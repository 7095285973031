:root {
    --max-content-width: 1200px;
    --michelin-yellow: #FCE500;
}

html, body, div {
    display: block;
    position: relative;
}

.retrospective header {
    padding: 0.75rem 1.5rem;
    border-bottom: 2px solid #1B386C;
    background: #27509B;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.retrospective header > div {
    width: 100%;
    max-width: var(--max-content-width);
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.retrospective main {
    color: #FFFFFF;
    width: 100%;
    background: linear-gradient(102deg, #1B386C 17.67%, #27509B 90.51%);
    font-family: 'Open Sans', 'Arial', Helvetica, Geneva, sans-serif;
    min-height: calc(100vh - 72px);
    Display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    flex-wrap: nowrap;
}

.retrospective section {
    height: auto;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
}

.retrospective h1 {
    font-size: 1.8rem;
    font-style: italic;
    font-weight: 700;
    line-height: normal;
    margin-bottom: 10px;
    text-align: center;
}

.retrospective h1 > span {
    text-transform: uppercase;
    font-style: normal;
}

.retrospective h2 {
    text-transform: uppercase;
    font-size: 1.5rem;
    color: #FFF;
    text-align: center;
    font-style: italic;
    font-weight: 600;
    line-height: normal;
    margin: 24px 0;
    max-width: 50%;
}

.retrospective h3 {
    color: var(--michelin-yellow);
    font-size: 1rem;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin: 24px 0 6px;
}

.retrospective hr {
    background-color: var(--michelin-yellow);
    width: 100%;
    height: 6px;
}


.retrospective img.icon {
    width: 63px;
    height: 63px;
    margin-top: 16px;
}

.retrospective img.icon.after {
    margin-top: 0;
    margin-bottom: 24px;
}

.retrospective button {
    background: linear-gradient(92deg, #B1A001 4.86%, #AF7D16 117.94%);
    padding: 6px;
    border-radius: 35px;
    box-shadow: 13.5px 15px 15px 6px rgba(0, 0, 0, 0.20);
    cursor: pointer;
    margin: 24px 0 96px;
    border: 0;
}
.retrospective button div {
    padding: 1rem;
    border-radius: 30px;
    background: linear-gradient(92deg, var(--michelin-yellow) 4.86%, #F9A825 117.94%);
}

.retrospective button:active {
    opacity: 0.9;
}

.retrospective button > img {
    width: 63px;
    height: 63px;
}

.retrospective .content {
    width: 100%;
    max-width: var(--max-content-width);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    padding: 0 36px;
}

.retrospective .experience-card-list {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    flex-direction: row;
    width: 80%;
    margin: 24px 0;
}

.retrospective .experience-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    width: auto;
    max-width: 300px;
    min-width: 190px;
    padding: 36px 34px;
    gap: 24px;
    border-radius: 33px;
    border: 1.5px solid rgba(109, 161, 255, 0.50);
    background: rgba(39, 80, 155, 0.50);
}

.retrospective .experience-card > div:nth-child(2) > p {
    font-size: 18px;
    line-height: 24px;
    margin: 6px 0;
}

.retrospective .experience-card > img {
    position: absolute;
    top: -15px;
    left: -15px;
    width: 48px;
    height: 48px;
}

.retrospective .trophy {
    text-align: center;
    width: 321px;
    height: 354px;
    padding: 57px;
    margin-top: 24px;
}

.retrospective .trophy > img {
    position: absolute;
    top: 0;
    left: 0;
    width: 321px;
    height: 354px;
}


.retrospective .medal-list {
    display: flex;
    flex-direction: row;
    gap: 12px;
    justify-content: center;
    align-items: flex-start;
    height: 75px;
    width: auto;
    margin: 24px auto;
}

.retrospective .medal-list > img {
    height: 75px;
    width: 45px;
}

.retrospective .dark-card {
    margin: 24px 0;
    padding: 24px 12px;
    background: rgba(0, 0, 0, 0.30);
    border-radius: 6px;
    height: auto;
    width: 100%;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
    gap: 24px;
}

.dark-card.column {
    flex-direction: column;
}
.retrospective .dark-card p {
    margin-top: 0;
}

.retrospective .car-value-list {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    gap: 24px;
}

.retrospective .car-value {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    height: 63px;
    min-width: 250.5px;
    padding: 3px 24px 3px 12px;
    border-left: 6px solid #ffffff;
    background: linear-gradient(270deg, rgba(39, 80, 155, 0.00) 0.1%, #27509B 100%);
}

.retrospective .car-value p.emphasis {
    text-align: right;
    font-size: 27px;
}

.retrospective .car-value > p:nth-child(1) {
    flex-grow: 1;
    width: auto;
}

.retrospective .car-value > div:nth-child(1) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60px;
    min-width: 60px;
    height: 60px;
}

.retrospective .dimension-list {
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    justify-content: flex-start;
}

.retrospective .dimension {
    width: 177px;
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: center;
    justify-content: flex-start;
}

.retrospective .dimension p {
    text-align: center;
}

.retrospective .dimension p.label2 {
    font-size: 9px;
    line-height: 10.8px;
    width: 65%;
    margin-bottom: 6px;
}

.retrospective .dimension p:nth-child(1) {
    margin-bottom: 0;
    font-size: 15px;
    line-height: 20.4px;
}

.retrospective .dimension .pressures-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    gap: 0;
    align-items: center;
    justify-content: space-evenly;
    width: 80%;
}

.retrospective .dimension .pressures-line p {
    font-size: 12px;
    line-height: 16.5px;
    text-align: center;
    margin-bottom: 0;
}

.retrospective .best-chrono {
    display: grid;
    width: 100%;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    grid-auto-flow: row;
    grid-template-areas: ". . .";
    grid-gap: 10px;
    grid-auto-rows: minmax(100px, auto);
}

.retrospective .best-chrono .circuit {
    grid-column: 1 / 2;
    grid-row: 1;
}
.retrospective .best-chrono .chrono {
    grid-column: 3 / 5;
    grid-row: 1;
}
.retrospective .best-chrono .medal {
    grid-column: 5;
    grid-row: 1;
}


.retrospective .best-chrono .circuit,
.retrospective .best-chrono .medal {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;
}

.retrospective .best-chrono .circuit {
    justify-content: flex-start;
    gap: 24px;

    grid-column: 1 / 3;
    grid-row: 1;
}

.retrospective .best-chrono .medal {
    justify-content: flex-end;
}

.retrospective .best-chrono .circuit img {
    height: 87px;
    width: auto;
    max-width: 768px;
}

.retrospective .best-chrono div {
    width: auto;
    /* min-width: 192px; */
    max-width: 768px;
}
.retrospective .best-chrono .chrono {
    min-width: 33%;
}

.retrospective .best-chrono .medal div {
    text-align: center;
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;
    align-items: center;
    justify-content: center;
    gap: 0;
    max-width: 65%;
    width: auto;
}
.retrospective .best-chrono .medal p.bold {
    margin-bottom: 2px;
}
.retrospective .best-chrono .medal p.label2 {
    width: 70%;
}

.retrospective p.subtitle {
    margin-top: 0;
    font-size: 1.125rem;
    font-style: italic;
    font-weight: 400;
    line-height: normal;
}

.retrospective p.emphasis {
    font-size: 3rem;
    font-weight: 900;
    line-height: normal;
    width: 100%;
    margin: 0;
    font-style: italic;
}

.retrospective p.label1 {
    text-align: center;
    width: 100%;
    font-size: 0.93rem;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    margin: 0;
}

.retrospective p.label2 {
    width: 100%;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 16.5px;
    margin: 0;
}

.retrospective p.bold {
    width: 100%;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 24px;
    margin-bottom: 12px;
}

.retrospective .pressure-color-line {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    height: 6px;
}

.retrospective .pressure-color-line > div {
    height: 100%;
}

.retrospective .pressure-color-line .white {
    background-color: #ffffff;
    width: 1.5px;
}

.retrospective .pressure-color-line .green {
    background-color: #84BD00;
    width: 39px;
}

.retrospective .pressure-color-line .orange {
    background-color: #F9A825;
    width: 25.5px;
}

.retrospective .pressure-color-line .red {
    background-color: #B71C1C;
    width: 33px;
}

@media (max-width: 992px) {
    .retrospective .experience-card-list {
        width: 90%;
        gap: 24px;
    }
    .retrospective .best-chrono .medal div {
        max-width: 75%;
    }
}

@media (max-width: 768px) {
    .retrospective .best-chrono .medal div {
        max-width: 90%;
    }
    .retrospective h2 {
        max-width: 75%;
    }
    .retrospective .best-chrono .circuit,
    .retrospective .best-chrono .medal {
        flex-direction: column;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        width: 100%;
        gap: 0;
    }
}

@media (max-width: 576px) {
    header img:first-child {
        height: auto;
        width: 45%;
    }
    header img:last-child {
        height: auto;
        width: 45%;
    }
    .retrospective h1 {
        font-size: 1.65rem;
        max-width: 90%;
    }

    .retrospective h2 {
        font-size: 1.5rem;
        max-width: 90%;
    }

    .retrospective p.subtitle {
        max-width: 90%;
    }

    .retrospective .experience-card-list {
        flex-direction: column;

        align-items: center;
        justify-content: flex-start;
        gap: 48px;
    }

    .retrospective .dark-card {
        padding: 24px;
    }
    .retrospective .dark-card,
    .retrospective .best-chrono {
        min-width: 260px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        gap: 24px;
    }

    .retrospective .dark-card .dimension-list {
        flex-direction: column;
        align-items: center;
        justify-content: flex-start;
        text-align: center;
        width: 100%;
    }

    .retrospective .car-value,
    .retrospective .car-value-list {
        width: 100%;
    }
    .retrospective .best-chrono .medal div {
        max-width: 100%;
    }
    .retrospective .best-chrono .chrono {
        min-width: 100%;
        text-align: center;
    }

    .retrospective .dark-card.column {
        gap: 48px;
    }

    .retrospective .best-chrono {
        display: flex;
        flex-direction: column;
        position: relative;
        gap: 24px;
        align-items: center;
        justify-content: flex-start;
    }
}

@media (max-width: 380px) {
    header img:first-child {
        height: auto;
        width: 80%;
    }
    header img:last-child {
        height: auto;
        width: 80%;
    }
    .retrospective .content {
        padding: 0;
    }

    .retrospective header > div {
        flex-direction: column;
        gap: 24px;
    }
}
